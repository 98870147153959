/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Hidden, colors } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import ApartmentIcon from '@material-ui/icons/Apartment';
import VideocamIcon from '@material-ui/icons/Videocam';
import BusinessIcon from '@material-ui/icons/Business';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportIcon from '@material-ui/icons/Report';
import VpnKeyOutlined from '@material-ui/icons/VpnKey';
import LockOpen from '@material-ui/icons/LockOpen';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SettingsRemote from '@material-ui/icons/SettingsRemote';
import Settings from '@material-ui/icons/Settings';
import Timeline from '@material-ui/icons/Timeline';
import { DAILY_ERRORS_TITLE } from '../../features/daily_errors/constants';
import Content from '../Content';

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  badgeDot: {
    height: 9,
    minWidth: 9,
  },
  onlineBadge: {
    backgroundColor: colors.green[600],
  },
  awayBadge: {
    backgroundColor: colors.orange[600],
  },
  busyBadge: {
    backgroundColor: colors.red[600],
  },
  offlineBadge: {
    backgroundColor: colors.grey[300],
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200],
  },
}));

const NavBar = ({ openMobile, onMobileClose }) => {
  const match = useRouteMatch();
  const classes = useStyles();
  const location = useLocation();
  const [navItems, setNavItems] = useState([]);
  const communityID = match.params.id;

  const portalV3Host = process.env.PORTAL_V3_HOST;
  const buildNavigationItems = () => {
    const items = [
      {
        title: 'Management Companies',
        href: `/admin/companies`,
        icon: BusinessIcon,
      },
      {
        title: 'Communities',
        href: '/admin/communities',
        icon: ApartmentIcon,
      },
      !!portalV3Host && {
        title: 'Cameras',
        href: `${portalV3Host}/admin/cameras`,
        icon: VideocamIcon,
      },
      {
        title: 'Members',
        href: '/admin/members',
        icon: PeopleIcon,
      },
      {
        title: 'Access Log',
        href: `${portalV3Host ?? ''}/admin/access_log`,
        icon: LockOpen,
      },
      {
        title: 'Access Points',
        href: '/admin/devices',
        icon: MeetingRoomIcon,
      },
      {
        title: 'Remotes',
        href: `${portalV3Host ?? ''}/admin/remotes`,
        icon: SettingsRemote,
      },
      {
        title: 'Product updates',
        href: '/admin/product_updates',
        icon: SettingsRemote,
      },
      {
        title: 'Disconnect Analytics',
        href: '/admin/device_events',
        icon: Timeline,
      },
      {
        title: 'Device Open Analytics',
        href: '/admin/device_open_events',
        icon: Timeline,
      },
      {
        title: 'System Jobs',
        divider: true,
        href: '/admin/jobs',
        icon: VpnKeyOutlined,
      },
      {
        title: DAILY_ERRORS_TITLE,
        href: `/admin/daily_errors`,
        icon: ErrorOutlineIcon,
        error: true,
      },
      {
        title: 'Unused Access Points',
        href: `/admin/unused_access_points_report`,
        icon: ReportIcon,
        error: true,
      },
      {
        title: 'Settings',
        divider: true,
        href: '/admin/settings',
        icon: Settings,
      },
    ].filter(Boolean);

    setNavItems(items);
  };

  useEffect(() => {
    buildNavigationItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityID]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer,
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Content navItems={navItems} />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer,
          }}
          open
          variant="persistent"
        >
          <Content navItems={navItems} />
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
